<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addtype">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="title" :label="$t('jc.biaoti')"></el-table-column>
      <el-table-column
        prop="content"
        :label="$t('jc.neirong')"
      ></el-table-column>
      <el-table-column prop="typeids" :label="$t('jc.jcfl')"></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="180px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong')" prop="content">
          <el-input type="textarea" v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(美国)'" prop="title">
          <el-input v-model="infoform.title_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(美国)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_en"></el-input>
        </el-form-item>
				
				<el-form-item :label="$t('jc.biaoti') + '(越南)'" prop="title">
				  <el-input v-model="infoform.title_vi"></el-input>
				</el-form-item>
				<el-form-item :label="$t('jc.neirong') + '(越南)'" prop="content">
				  <el-input type="textarea" v-model="infoform.content_vi"></el-input>
				</el-form-item>
				<el-form-item :label="$t('jc.biaoti') + '(中文繁体)'" prop="title">
				  <el-input v-model="infoform.title_tw"></el-input>
				</el-form-item>
				<el-form-item :label="$t('jc.neirong') + '(中文繁体)'" prop="content">
				  <el-input type="textarea" v-model="infoform.content_tw"></el-input>
				</el-form-item>
				
        <el-form-item :label="$t('jc.biaoti') + '(西班牙)'" prop="title">
          <el-input v-model="infoform.title_es"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(西班牙)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_es"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(法国)'" prop="title">
          <el-input v-model="infoform.title_fr"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(法国)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_fr"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(意大利)'" prop="title">
          <el-input v-model="infoform.title_it"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(意大利)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_it"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(俄罗斯)'" prop="title">
          <el-input v-model="infoform.title_ru"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(俄罗斯)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_ru"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl')" prop="typeids">
          <el-select
            multiple
            v-model="infoform.typeids"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in helptype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editform"
        label-width="180px"
        ref="editform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.biaoti')" prop="title">
          <el-input v-model="editform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong')" prop="content">
          <el-input type="textarea" v-model="editform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(英文)'" prop="title">
          <el-input v-model="editform.title_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(英文)'" prop="content">
          <el-input type="textarea" v-model="editform.content_en"></el-input>
        </el-form-item>
				
				<el-form-item :label="$t('jc.biaoti') + '(越南)'" prop="title">
				  <el-input v-model="editform.title_vi"></el-input>
				</el-form-item>
				<el-form-item :label="$t('jc.neirong') + '(越南)'" prop="content">
				  <el-input type="textarea" v-model="editform.content_vi"></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('jc.biaoti') + '(中文繁体)'" prop="title">
				  <el-input v-model="editform.title_tw"></el-input>
				</el-form-item>
				<el-form-item :label="$t('jc.neirong') + '(中文繁体)'" prop="content">
				  <el-input type="textarea" v-model="editform.content_tw"></el-input>
				</el-form-item>
				
        <el-form-item :label="$t('jc.biaoti') + '(西班牙)'" prop="title">
          <el-input v-model="editform.title_es"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(西班牙)'" prop="content">
          <el-input type="textarea" v-model="editform.content_es"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(法国)'" prop="title">
          <el-input v-model="editform.title_fr"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(法国)'" prop="content">
          <el-input type="textarea" v-model="editform.content_fr"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(意大利)'" prop="title">
          <el-input v-model="editform.title_it"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(意大利)'" prop="content">
          <el-input type="textarea" v-model="editform.content_it"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(俄罗斯)'" prop="title">
          <el-input v-model="editform.title_ru"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(俄罗斯)'" prop="content">
          <el-input type="textarea" v-model="editform.content_ru"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.jcfl')" prop="typeids">
          <el-select
            multiple
            v-model="editform.typeids"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in helptype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      // 教程分类
      helptype: [],
      // 添加教程数据对象
      infoform: {
        title: '',
        content: '',
        title_en: '',
        content_en: '',
        title_es: '',
        content_es: '',
        title_fr: '',
        content_fr: '',
        title_ru: '',
        content_ru: '',
        title_it: '',
        content_it: '',
				title_vi: '',
				content_vi: '',
				title_tw: '',
				content_tw: '',
        typeids: null
      },
      dialogvisible: false,
      formrules: {
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        typeids: [
          { required: true, message: '请选择教程分类', trigger: 'select' }
        ]
      },
      // 编辑教程数据对象
      editform: {
        title: '',
        content: '',
        title_en: '',
        content_en: '',
        title_es: '',
        content_es: '',
        title_ru: '',
        content_ru: '',
        title_fr: '',
        content_fr: '',
        title_it: '',
        content_it: '',
				title_vi: '',
				content_vi: '',
				title_tw: '',
				content_tw: '',
        typeids: null,
        id: ''
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/help/helplist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    async addtype() {
      this.dialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/help/addhelpinfo')
      if (data) {
        if (data.code === 200) {
          this.helptype = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.infoform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 提交添加分类
    submit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/addhelpinfo',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(data.msg)
            this.handleClose()
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/help/edithelpinfo/id/' + id)
      console.log(data)
      this.editform.title = data.data.info.title
      this.editform.content = data.data.info.content
      this.editform.title_en = data.data.info.title_en
      this.editform.content_en = data.data.info.content_en
      this.editform.title_es = data.data.info.title_es
      this.editform.content_es = data.data.info.content_es
      this.editform.title_it = data.data.info.title_it
      this.editform.content_it = data.data.info.content_it
      this.editform.title_fr = data.data.info.title_fr
      this.editform.content_fr = data.data.info.content_fr
      this.editform.title_ru = data.data.info.title_ru
      this.editform.content_ru = data.data.info.content_ru
			
			this.editform.title_tw = data.data.info.title_tw
			this.editform.content_tw = data.data.info.content_tw
			this.editform.title_vi = data.data.info.title_vi
			this.editform.content_vi = data.data.info.content_vi
			
      this.editform.typeids = data.data.info.typeids
      this.editform.id = data.data.info.id
      this.helptype = data.data.types
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/edithelpinfo',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.infoform = ''
      this.$refs.editform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('quding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/help/delhelpinfo', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
